<template>
  <div>
    <div class="container">
      <div v-if="!isSuccess">
        <div class="py-5 text-center">
          <img
            class="d-block mx-auto mb-4"
            src="/img/brand/abplus-logo.png"
            alt=""
            width="120"
          />
          <h2>REQUEST FOR ACCOUNT DELETION</h2>
          <p class="text-small">
            If you’d like to delete your personal profile information from AB
            Plus, please complete this form with details about your profile to
            submit a request.
          </p>
        </div>

        <el-alert
          title="Important:"
          type="warning"
          description="Note that when the account gets deleted any registered account product will be deleted as well, and you will no longer be able to access it."
          :closable="false"
          show-icon
        >
        </el-alert>

        <el-form
          ref="formRequestDelete"
          :model="formData"
          size="small"
          :rules="formDataRule"
        >
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="formData.name"
              class="form-control-alternative"
            ></el-input>
          </el-form-item>
          <el-form-item label="Member code" prop="membercode">
            <el-input
              v-model="formData.membercode"
              class="form-control-alternative"
            ></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="formData.email"
              class="form-control-alternative"
            ></el-input>
          </el-form-item>
          <el-form-item label="Reason" prop="reason">
            <el-input
              v-model="formData.reason"
              class="form-control-alternative"
              type="textarea"
              v-input-filter
              :rows="5"
            ></el-input>
          </el-form-item>
          <div class="mt-10">
            <el-button @click="gotoSite" type="success"> Cancel </el-button>
            <el-button
              :disabled="loading"
              :loading="loading"
              type="danger"
              @click="submitValidate"
            >
              Please Delete my Account
            </el-button>
          </div>
        </el-form>
      </div>
      <div v-else>
        <div class="py-5 text-center">
          <img
            class="d-block mx-auto mb-4"
            src="/img/brand/abplus-logo.png"
            alt=""
            width="120"
          />
          <h2>Thank you for your request.</h2>
          <p class="text-small">
            In the process to verify your identity, we will send an email to the
            email address you provide which matches our records and wait for
            your response. In some instances, we may also ask for additional
            information to ensure we verify your identity before complying.
          </p>
          <el-button @click="gotoSite" type="success"> Enter site </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postDeleteAccount } from "@/api/user";
export default {
  data() {
    return {
      loading: false,
      isSuccess: false,
      formData: {
        membercode: "",
        name: "",
        email: "",
        reason: "",
        "g-recaptcha-response": "",
      },
      formDataRule: {
        name: [
          {
            required: true,
            message: "Please enter your name",
            trigger: "blur",
          },
        ],
        membercode: [
          {
            required: true,
            message: "Please enter your member code",
            trigger: "blur",
          },
        ],

        email: [
          {
            required: true,
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        reason: [
          {
            required: true,
            message: "Please enter reason",
            trigger: "blur",
          },
          {
            message: " Reason must put more than 6 character ",
            min: 6,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitValidate() {
      this.$refs.formRequestDelete.validate((valid) => {
        if (valid) {
          this.$swal({
            title: "Confirmation",
            html: `Are you sure you want to delete your account. Continue?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Comfirm",
            cancelButtonText: "Cancel",
            showCloseButton: true,
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.submitForm();
            } else {
              this.$message(`Action Canceled`);
            }
          });
        }
      });
    },
    async submitForm() {
      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("login");
        this.formData["g-recaptcha-response"] = token;
      } catch (error) {
        this.loading = false;
      }

      postDeleteAccount(this.formData)
        .then(({ result, message }) => {
          this.loading = false;
          if (result) {
            this.$swal(
              `Message`,
              `Request delete account is success`,
              "success"
            ).then(() => {
              this.$router.push({ query: { type: "success" } });
              this.isSuccess = true;
            });
          } else {
            this.$swal("Message", message, "error");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    gotoSite() {
      window.location.href = "https://abmoneyplus.com/";
    },
  },
  mounted() {
    if (this.$route.query.type && this.$route.query.type == "success") {
      this.isSuccess = true;
    }
  },
};
</script>

<style lang="scss" scoped></style>
